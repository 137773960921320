@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";


.Icon {
  vertical-align: middle;
  display: inline-block;
  width: $size-medium;
  height: $size-medium;
  color: inherit;
  fill: $dark;

  &.is-medium {
    width: $size;
    height: $size;
  }
}