@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.Main {
  flex: 1 1 auto;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  // @include transition(opacity);


  @include tablet-only() {
    padding-left: $sidenav-width;
  }

  .sidebar-is-open & {
    // padding-left: $side-width;

    @include until($desktop) {
      // padding-left: $navigation-width;
    }

    @include mobile() {
      // padding-left: 0;
      opacity: 0.2;
    }
  }

  .container {
    width: 100%;
    max-width: $main-width-max;
    min-width: $main-width-min;
    margin: 0 auto;
    padding: {
      left: $main-gutter;
      right: $main-gutter;
    }
  }
}

.MainNavigation {
  @include transition(left);
  position: relative;
  z-index: 8;

  .container {
    position: relative;
  }

  .NavBar {
    display: flex;
    justify-content: space-between;

    &-left {
      overflow: hidden;
      width: 100%;
      height: $size-big;
    }

    &-item {
      color: $grey-dark;
      display: inline-block;
      

      &:hover {
        color: $grey-darkest;
      }

      &:active {
        color: $link;
      }

      &:not(:last-child) {
        margin-right: $size-small;
      }

      &.is-active {
        color: $link;
        font-weight: $weight-medium;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }
    }

    &-clientcode {
      color: $grey;
      text-transform: uppercase;
      font-size: 0.9rem;
    }

    .Avatar {
      margin-right: $size-tiny;
    }
  }

  .SubNavBar {
  }
  
  .MainPageNavigation {
    position: relative;
    z-index: 7;
    background-color: $white;
    padding: 0;
    line-height: $size-big;
    background-image: linear-gradient(-180deg, #FFFFFF 0%, rgba(#F3F4F5, 0.5) 100%);
    // height: $size-big;
    @include shadow();
  }
  
  .MainSubNavigation {
    position: relative;
    z-index: 6;
    background-image: linear-gradient(-180deg, $beef 0%, rgba($beef, 0) 100%);
    line-height: $size*0.9; // 28px
    min-height: $size;
    position: absolute;
    left: 0;
    right: 0;
    pointer-events: none;
    padding: $size-small/1.5 0 {
      bottom: $size;
    } 
  
    &-item {
      display: inline-block;
      border-radius: 8px;
      text-decoration: none;
      color: $grey-dark;
      margin-right: $size-tiny;
      font-size: 0.9rem;
      pointer-events: auto;
      padding: 0 $size/2;
      background: rgba($white, 0.9);
      @include transition();
  
      &:hover {
        color: $dark;
        box-shadow: 0px 6px 16px 0px rgba($dark, 0.1);
        background-color: $white;
      }
  
      &.is-active {
        color: $dark;
        background: $white;
        font-weight: 500;
        box-shadow: inset 0px 0px 0px 2px $purple-light, 0px 6px 16px 0px rgba($dark, 0.1);
      }
    }
  }
}


.MainScroll {
  $padding-top: $size*1.5;
  
  flex: 1;
  height: 100%;
  -webkit-overflow-scrolling: auto;
  overflow: auto;
  position: relative;
  z-index: 6;
  padding-top: $padding-top;

  .MainContent {

    .container {
      padding: $main-gutter;
    }
  }
}

.MainHeader {
  @include block;
}

