@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.Title {
  margin: 0;
  color: $dark;
  font-weight: $weight-bold;
  line-height: $size;

  &.is-1 {
    font-size: $title-1;
  }

  &.is-2 {
    font-size: $title-2;
  }
  
  &.is-3 {
    font-size: $title-3;
  }
}

.SubTitle {
  display: block;
}