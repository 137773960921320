@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";



.Avatar {
  display: inline-block;
  width: $size;
  height: $size;
  text-align: center;
  font-size: 1.1rem;
  color: $grey;
  background: $grey-lighter;
  font-weight: $weight-medium;
  line-height: $size;
  border-radius: $size;
  box-shadow: 0px 1px 1px 0px rgba($dark, 0.03);
  @include transition();
  text-transform: uppercase;
  position: relative;

  &.is-active {
    background-image: linear-gradient(to top, rgb(118, 1, 158) 30%, rgb(88, 0, 150) 60%);
    color: $white;
    font-weight: $weight-medium;
  }

  &.is-business {
    border-radius: $radius-small;
  }

  &.has-noshape {
    background-color: transparent;
  }
}