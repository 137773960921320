@mixin block {
  display: block;
  position: relative;ition: relative;

  &:not(:last-child) {
    margin-bottom: $size-medium;
  }
}

@mixin center($width, $height: 0) {
  position: absolute;
  @if $height != 0 {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$height} / 2));
  }
  @else {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$width} / 2));
  }
}

@mixin loader($color: $white) {
  animation: spinAround 700ms infinite linear;
  border: 2px solid $color;
  border-radius: 100%;
  border-right-color: rgba($color, 0.3);
  border-top-color: rgba($color, 0.3);
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
  box-sizing: border-box;
}

@mixin control {
  $control-padding-vertical: 0.25rem;
  $control-padding-horizontal: 0.75rem;

  padding: $control-padding-vertical $control-padding-horizontal;
  display: inline-flex;
  height: $button-height;
  position: relative;
}




// HELPERS                  

@mixin box {
  background: $white;
}

@mixin shadow {
  box-shadow: 0 1px 0px 0 rgba($dark,0.1), 0 1px 2px 0 rgba($dark,0.1);
}

@mixin bottomBorder {
  box-shadow: 0 1px 0 0 $card-border;
}
@mixin bottomTop {
  box-shadow: 0 -1px 0 0 $card-border;
}

@mixin transition($target: all, $speed: $speed, $delay: 0s, $ease: $ease-in-out-quad) {
  will-change: $target;
  transition: all $speed $delay $ease;
  transition-property: $target;
}

@mixin icon-color($color: $grey, $alt: $color) {
  color: $color;
  fill: $color;
}

// DEVICES

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @if $widescreen-enabled {
    @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
      @content;
    }
  }
}

@mixin until-widescreen {
  @if $widescreen-enabled {
    @media screen and (max-width: $widescreen - 1px) {
      @content;
    }
  }
}

@mixin widescreen {
  @if $widescreen-enabled {
    @media screen and (min-width: $widescreen) {
      @content;
    }
  }
}

@mixin widescreen-only {
  @if $widescreen-enabled and $fullhd-enabled {
    @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
      @content;
    }
  }
}

@mixin until-fullhd {
  @if $fullhd-enabled {
    @media screen and (max-width: $fullhd - 1px) {
      @content;
    }
  }
}

@mixin fullhd {
  @if $fullhd-enabled {
    @media screen and (min-width: $fullhd) {
      @content;
    }
  }
}