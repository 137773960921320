@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

// VARIABLES

$card-background: $white;
$card-radius: $radius;
$card-border: $grey-lightest;
$card-shadow: 0 1px 2px 0 rgba($dark,0.07), 0 1px 2px 0 rgba($dark,0.15);


// CSS

.Card {
  @include block();
  background-color: $white;
  box-shadow: $card-shadow;
  border-radius: $radius;
  padding: 0 $size-medium;

  &.is-note {
    background-color: lighten(yellow, 42);
    color: #5C5C4F;
    border-radius: 0;
    text-align: center;
    // font-size: 1.25rem;
  }
}

.CardHeader {
  padding-top: $size-medium - $size-tiny;
  padding-bottom: $size-small;
  position: relative;
  
  @include bottomBorder();

  @include until($tablet) {
    .level-left + .level-right {
      margin-top: 0;
    }
    .buttons {
      justify-content: flex-start;
    }
  }
} 

.CardContent {
  ul,
  ol {
    list-style: outside;
    padding-left: 1.15rem;
  }

  line-height: 1.7;

  .Title {
    margin-bottom: $size-tiny;
  }
}

.CardSection {
  padding: $size-medium 0;

  &:not(:last-of-type) {
    @include bottomBorder();
  }
}

.CardFooter {
  padding-top: $size-medium;
  padding-bottom: $size-medium;
  text-align: right;
  
  @include bottomTop();
} 