// COLORS

// base
$purple: #8241AA;
$purple-light: #A476C1;
$yellow: #E6C543;
$red: #CE2554;
$blue: #2B74DF;
$green: #00AA65;
$green-dark: darken($green, 30);
$white: white;

$beef: #EBEEF1;
$beef-light: #F5F6F8;

$dark: #2E3E4F;

$grey-darkest: $dark;
$grey-darker: #515E6D;
$grey-dark: #687480;
$grey: #969EA7;
$grey-light: #C5C9CE;
$grey-lighter: #DCDFE1;
$grey-lightest: #E8EAEC;
$grey-white: #F3F4F5;

$link: $purple;
$link-hover: darken($link, 15);
$link-active: darken($link, 30);

// status
$warning: $yellow;
$success: $green;
$danger: $red;
$info: $blue;

// DEVICES
$gap: 64px !default;
$tablet: 769px !default;
$desktop: 960px + (2 * $gap) !default;
$widescreen: 1152px + (2 * $gap) !default;
$widescreen-enabled: true !default;
$fullhd: 1344px + (2 * $gap) !default;
$fullhd-enabled: true !default;
$variable-columns: true !default;


// RADIUS
$radius-small: 4px;
$radius: 6px;
$radius-large: 8px;

// SIZES
$size: 2rem; // 32px
$size-micro: $size/8; // 4px
$size-tiny: $size/4; // 8px
$size-small: $size/2; // 16px
$size-medium: $size*0.75; // 24px
$size-big: $size*2; // 64px
$size-large: $size*2.5; // 80px
$size-huge: $size*3; // 96px

$gutter: $size;

// WEIGHTS
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 600;
$weight-black: 700;

// TEXT
$text-size: 16px;
$text-color: $grey-darker;
$text-weight: $weight-regular;
$text-height: 1.35;

// TITLES
$title-1: 1.3rem;
$title-2: 1.2rem;
$title-3: 1.1rem;
$title-4: 1rem;

// SPEED
$speed-fast: 0.1s;
$speed: 0.2s;
$speed-slow: 0.4s;
$speed-slowmo: 2s;

// ANIMATIONS
$ease-in-out-quad: cubic-bezier(.455, .03, .515, .955);
$ease-out-quad: cubic-bezier(.25, .46, .45, .94);
$ease-in-quad: cubic-bezier(.55, .085, .68, .53);

// BODY
$body-background: $beef;


// SUBNAV
$sidesubnav-width: $size*8;
$sidesubnav-background: $white;
$sidesubnav-width-closed: $size-big; // 64px

// NAV
$sidenav-background: $beef-light;
$sidenav-width: $size-big;

// SIDE
$side-background: $white;
$side-width: $sidesubnav-width + $sidenav-width;

// MAIN
$main-width-min: 300px;
$main-width-max: 1120px;
$main-gutter: $gutter;