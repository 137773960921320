@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

// VARIABLES

$button-radius: $radius-small;
$button-border: inset 0px 0px 0px 1px $grey-lighter;
$button-height: $size-small*2;
$button-text-height: $size-medium;
$button-padding-vertical: 0.25rem;
$button-padding-horizontal: 0.75rem;

$button-background: $white;
$button-background-hover: $grey-white;
$button-background-active: $grey-lightest;

$button-primary-color: $white;
$button-primary-background: $green;
$button-primary-background-hover: darken($green, 5);
$button-primary-background-active: darken($green, 10);

$button-warning-color: $white;
$button-warning-background: $red;
$button-warning-background-hover: darken($red, 5);
$button-warning-background-active: darken($red, 10);



// CSS

.Button {
  border: none;
  cursor: pointer;
  color: $text-color;
  border-radius: $button-radius;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  justify-content: center;
  padding: $button-padding-vertical $button-padding-horizontal;
  outline: none;
  line-height: $text-height;
  @include transition($speed: $speed-fast);
  @include control;

  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
  }

  .icon {
    display: inline-block;
    margin-left: -$button-padding-horizontal/2;
    margin-right: $button-padding-horizontal/2;
    width: $size-medium;
    height: $size-medium;
    line-height: 1.25rem;

    &:only-child {
      margin: 0 (-$button-padding-horizontal/1.5);
    }

    .Icon {
      @include transition($speed: $speed-fast);
    }
  }

  .text {
    line-height: $button-text-height;
  }

  &.is-secondary {
    background-color: $button-background;
    box-shadow: $button-border;
    
    &:hover {
      background-color: $button-background-hover;
    }
    &:active {
      background-color: $button-background-active;
    }
  }

  &.is-primary {
    color: $button-primary-color;
    background-color: $button-primary-background;
    box-shadow: 0px 1px 2px rgba($green-dark, 0.15);

    &:hover {
      box-shadow: 0px 1px 4px rgba($green-dark, 0.2);
      background-color: $button-primary-background-hover;
    }

    &:active {
      background-color: $button-primary-background-active;
    }
  }

  &.is-warning {
    color: $button-warning-color;
    background-color: $button-warning-background;
    box-shadow: 0px 1px 1px rgba($green-dark, 0.05);

    &:hover {
      box-shadow: 0px 1px 2px rgba($green-dark, 0.1);
      background-color: $button-warning-background-hover;
    }

    &:active {
      background-color: $button-warning-background-active;
    }
  }

  &.is-link {
    background-color: transparent;
    color: $link;
    border-radius: 0px;
    box-shadow: none;
    padding: {
      left: 0;
      right: 0;
    }

    .icon {
      margin-left: 0;
    }

    &:hover,
    &:active {
      background-color: inherit;
    }

    &:hover {
      color: $link-hover;
      text-decoration: underline;
    }

    &:active {
      color: $link-active;
    }
  }

  &.is-loading,
  &.is-loaded {
    opacity: 0.7;
  }


  &.is-loading {
    &:before {
      @include loader;
      @include center(1em);
      position: absolute;
    }
    pointer-events: none;

    .icon,
    .text {
      opacity: 0;
    }
  }

  &.is-loaded {
    .icon {
      @include center($size-medium);
      margin: 0;
    }
    .text {
      opacity: 0;
    }
  }
}

.buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  &.is-left {
    justify-content: flex-start;
  }

  &.is-centered {
    justify-content: center;
  }

  .Button:not(:last-child) {
    margin-right: $size-tiny;
  }
}