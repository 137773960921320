@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

$side-transition-speed: $speed-slow;

.Side {
  position: relative;
  display: flex;
  background: $side-background;
  filter: drop-shadow(0 0 1.5px rgba($dark, 0.3));
  z-index: 12;

  @include until($desktop) {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
  }

  @include mobile() {
    left: -$sidenav-width;
    @include transition(left);
    
    .sidebar-is-open & {
      left: 0;
    }
  }
}

.SideNav {
  width: $sidenav-width;
  background: $sidenav-background;
  height: 100vh;
  padding: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;

  .Logo {
    display: inline-block;
    margin-bottom: $size-tiny;
    margin-left: $size-small;
    @include transition();

    &:hover {
      opacity: 0.5;
      transform: translateY(-$size-micro)
    }
  }
}

.SideNavMenu {
  flex: 1 1;
}

.SideNavItem {
  display: block;
  color: inherit;
  text-align: center;
  padding: $size-tiny 0; 

  .label {
    font-size: 0.8rem;
    color: inherit;
  }

  .Avatar {
    transition: none;
    font-size: 1.2rem;
    color: inherit;
  }

  .SideNavItem-content {
    display: inline-block;
    border-radius: 8px;
    color: $grey;
    font-size: 0.9rem;
    padding: $size-tiny;
    margin-bottom: $size-micro;
    background: transparent;
    box-shadow: 0px 0px 0px 1px rgba($dark, 0.1);
    @include transition();
  }

  &:hover,
  &:focus {
    color: $dark;
    text-decoration: none;
    
    .SideNavItem-content {
      box-shadow: 0px 6px 16px 0px rgba($dark, 0.1);
      background-color: $white;
      color: inherit;
    }
  }

  &.is-active {
    .SideNavItem-content {
      color: $link;
      background: $white;
      font-weight: 500;
      box-shadow: inset 0px 0px 0px 2px $purple-light, 0px 6px 16px 0px rgba($dark, 0.1);
    }

  }
}


// SUB


.SideSubNav {
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0px;
  // transform: translateX(-$sidebar-width);
  @include transition(width);
  
  .toggle {
    display: block;
    width: 24px;
    height: 66px;
    cursor: pointer;
    position: absolute;
    bottom: 32px;
    right: -24px;
    transition: right 0.2s ease, opacity 0.2s ease;

    &-arrow {
      content: "";
      display: block;
      position: absolute;
      z-index: 9;
      left: 0;
      top: 21px;
      pointer-events: none;
      @include icon-color();
      @include transition();
      transform: rotate(-0.5turn);
    }

    &-shape {
      color: $beef-light;
    }

    &:hover {
      .toggle-arrow {
        @include icon-color($dark);
      }
    }
  }

  .container {
    overflow-y: auto;
    height: 100vh;
    position: relative;
  }

  .sidebar-is-open & {
    width: $sidesubnav-width;

    .toggle-shape {
      color: $sidesubnav-background;
    }

    .toggle-arrow {
      transform: rotate(0);
    }
  }
}

